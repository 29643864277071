<template>
  <div class="card-container">
    <div class="card">
      <img
        :src="require('@/assets/AvaliacaoRapida.png')"
        alt="Nova Avaliação"
        class="card-img"
      />
      <div class="card-content">
        <h1>Nova Avaliação Simplificada</h1>

        <button
          class="card-button"
          style="
            margin-top: 45px;
            padding: 15px 40px;
            background-color: #136e66;
            color: #ffffff;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s ease;
            display: block;
            width: 100%;
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          Acessar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

