import { createRouter, createWebHistory } from 'vue-router';
import DadosView from '../views/DadosView.vue';
import LoginView from '../views/LoginView.vue';
import HomeView from '@/views/homeVIew/HomeView.vue';
import NovoLaudoView from '@/views/formView/NovoLaudoView.vue';
import ConsultarlaudosView from '@/views/consultaView/ConsultarlaudosView.vue';
import ConfigView from '@/views/ConfigView.vue';
import AvaliacaoSimplificada from '@/views/formView/AvaliacaoSimplificada.vue';

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/dados',
        name: 'DadosView',
        component: DadosView,
    },
    {
        path: '/novo-laudo',
        name: 'NovoLaudo',
        component: NovoLaudoView,
    },
    {
        path: '/avaliacao-simplificada',
        name: 'NovaAvaliacao',
        component: AvaliacaoSimplificada,
    },
    {
        path: '/consultarlaudos',
        name: 'Consultarlaudos',
        component: ConsultarlaudosView,
    },
    {
        path: '/configuracoes',
        name: 'Configuracoes',
        component: ConfigView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
