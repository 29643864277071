<template>
  <div class="card-container">
  <div class="card">
    <img :src="require('@/assets/Novolaudo.png')" alt="Consulta Rápida" class="card-img" />
    <div class="card-content">
      <h1>Nova Avaliação Completa</h1>
      <button class="card-button">Acessar</button>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

