<template>
  <menu-component />
  <div class="config">
    <h1>Configurações</h1>
    <span>Defina um texto padrão para avaliação imobiliária</span>
    <p>Inserir ou editar texto padrão:</p>
    <textarea
      v-model="textoPadrao"
      placeholder="Digite aqui..."
      maxlength="500"
    ></textarea>
    <p>Tamanho máximo 500 caracteres</p>
    <div class="button-container">
      <button type="button" class="cancel-button" @click="cancel">
        Cancelar
      </button>
      <button
        type="submit"
        class="submit-button"
        @click.prevent="saveTextoPadrao"
        :disabled="isSaving"
      >
        {{ isSaving ? "Salvando..." : "Salvar" }}
      </button>
    </div>
  </div>
</template>

<script>
import MenuComponent from "@/components/layoutComponent.vue/MenuComponent.vue";
import axios from "axios";
import Swal from "sweetalert2"; // Importa SweetAlert2

export default {
  name: "TableView",
  components: {
    MenuComponent,
  },
  data() {
    return {
      textoPadrao: "", // Armazena o texto padrão
      isSaving: false, // Estado do botão de salvamento
      fiscalId: null, // ID do fiscal
    };
  },
  created() {
    this.fiscalId = sessionStorage.getItem("fiscal_id");
    if (!this.fiscalId) {
      console.error("ID do fiscal não encontrado.");
    }
  },
  methods: {
async fetchTextoPadrao() {
  if (!this.fiscalId) return;

  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/preferencia/usuario/${this.fiscalId}`,
      {
        headers: {
          "x-access-token": process.env.VUE_APP_API_TOKEN,
        },
      }
    );
    // Atribuir o texto padrão e o ID retornado pelo GET
    this.textoPadrao = response.data.texto_padrao || "";
    this.id = response.data.id || null; // Certifique-se de armazenar o ID
  } catch (error) {
    Swal.fire("Erro", "Erro ao buscar o texto padrão.", "error");
    console.error("Erro ao buscar o texto padrão:", error);
  }
},
async saveTextoPadrao() {
  if (this.textoPadrao.trim().length > 500) {
    Swal.fire(
      "Atenção",
      "O texto padrão não pode exceder 500 caracteres.",
      "warning"
    );
    return;
  }

  this.isSaving = true;
  try {
    await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/preferencia/texto_padrao`,
      {
        id: this.id, // Certifique-se de que o ID está definido corretamente
        id_usuario: this.fiscalId, // O ID do usuário que está editando
        texto_padrao: this.textoPadrao, // O texto atualizado
      },
      {
        headers: {
          "x-access-token": process.env.VUE_APP_API_TOKEN,
        },
      }
    );
    Swal.fire("Sucesso", "Texto padrão salvo com sucesso!", "success");
  } catch (error) {
    console.error("Erro ao salvar o texto padrão:", error);
    Swal.fire({
      title: "Erro",
      text: "Ocorreu um erro ao salvar o texto padrão.",
      icon: "error",
      confirmButtonText: "Ok!",
      didOpen: () => {
        const confirmButton = Swal.getConfirmButton();
        if (confirmButton) {
          confirmButton.style.cssText = `
            background-color: #FF9364 !important; 
            color: #FAFAFA !important; 
            border: none !important; 
            border-radius: 4px !important;  
            padding: 12px 24px !important;  
            font-size: 16px !important; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
            width: auto !important; 
            height: auto !important;  
            display: inline-block;
            box-sizing: border-box; 
        `;
        }
      },
    });
  } finally {
    this.isSaving = false;
  }
}
,

    cancel() {
      Swal.fire({
        title: "Tem certeza?",
        text: "Você está prestes a cancelar a ação.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        didOpen: () => {
          // Estiliza o botão "Cancelar"
          const cancelButton = Swal.getCancelButton();
          if (cancelButton) {
            cancelButton.style.cssText = `
        background-color: #F4F4F5 !important; 
        color: #18181B !important; 
        border: none !important; 
        border-radius: 4px !important;  
        padding: 10px 20px !important;  
        font-size: 16px !important; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
        width: 190px !important; 
        height: 46px !important;  
      `;
          }

          // Estiliza o botão "Confirmar"
          const confirmButton = Swal.getConfirmButton();
          if (confirmButton) {
            confirmButton.style.cssText = `
        background-color: #FF9364 !important; 
        color: #FAFAFA !important; 
        border: none !important; 
        border-radius: 4px !important;  
        padding: 12px 24px !important;  
        font-size: 16px !important; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;  
        width: auto !important; 
        height: auto !important;  
      `;
          }
        },
      });
    },
  },
  mounted() {
    this.fetchTextoPadrao();
  },
};
</script>
<style scoped>
.config {
  padding: 16px;
  background-image: url("/public/Fundotela.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
h1 {
  font-size: 28px;
  color: #136e66;
  margin-bottom: 20px;
  text-align: left;
}

.view-title {
  text-align: left;
  margin-bottom: 20px;
  font-size: 250%;
  border-bottom: 1px solid #136e66;
  padding-bottom: 10px;
}
span {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  color: #4f4f4f;
  border-bottom: 1px solid #136e66;
  padding-bottom: 10px;
}
.submit-button {
  color: #fff;
  background-color: #136e66;
}
.config textarea {
  width: 50%; /* Ajusta a largura */
  height: 150px; /* Altura adequada */
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none; /* Desabilita o redimensionamento */
}

.button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.cancel-button,
.submit-button {
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.cancel-button {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
}

.cancel-button:hover {
  background-color: #e5e5e5;
}

.submit-button:hover:not(:disabled) {
  opacity: 0.8;
}
</style>
