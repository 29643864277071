<template>
  <MenuComponent />
  <div class="home-container">
    <div class="container"><h1 class="view-title"> Avaliação Imobiliária</h1></div>
    <div class="cards-container">
      <div class="card" @click="goToForm">
        <card-form />
      </div>
      <div class="card" @click="goToAvaliacao">
        <card-avaliacao-rapida />
      </div>
      <div class="card" @click="goToPesquisa">
        <card-pesquisa />
      </div>
      <div class="card" @click="goToConsulta">
        <card-consulta />
      </div>
      <div class="card" @click="goToConfing">
        <card-config />
      </div>
    </div>
  </div>
</template>

<script>
import MenuComponent from '@/components/layoutComponent.vue/MenuComponent.vue';
import CardConsulta from './CardConsulta.vue';
import CardForm from './CardForm.vue';
import CardPesquisa from './CardPesquisa.vue';
import CardAvaliacaoRapida from './CardAvaliacaoRapida.vue';
import CardConfig from './CardConfig.vue';

export default {
  components: { CardForm, CardPesquisa, CardConsulta, MenuComponent, CardAvaliacaoRapida, CardConfig },
  methods: {
    goToForm() {
      this.$router.push({ name: 'NovoLaudo' }); 
    },
    goToAvaliacao() {
      this.$router.push({ name: 'NovaAvaliacao' }); 
    },
    goToPesquisa() {
      this.$router.push({ name: 'DadosView' }); 
    },
    goToConsulta() {
      this.$router.push({ name: 'Consultarlaudos' }); 
    },
    goToConfing() {
      this.$router.push({ name: 'Configuracoes' }); 
    }
  }
};
</script>

<style scoped>
/* Estilos para o container */
.home-container {
  text-align: center;
  padding: 50px;
  background-image: url("/public/Fundotela.png");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  min-height: 100vh;
}

.view-title {
  margin-bottom: 20px;
  font-size: 250%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
}

h1 {
  width: 926px;
  height: 78px;
  color: #177168;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}



.card-img {
  max-width: 100%;
  max-height: 200px; /* Definindo altura fixa para a imagem */
  width: auto; 
  height: auto;
  display: block;
  margin: 0 auto;
  padding-top: 30px;
  object-fit: cover; /* Garante que a imagem se ajuste sem distorção */
}

.card-content {
  text-align: center;
  padding: 16px;
}

.card-content h1 {
  margin: 0;
  color: #136E66;
  font-size: 28px;
}

.card-button {
  margin-top: 16px;
  padding: 15px 40px;
  background-color: #136E66;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: inline-block;
  min-width: 250px;
}

.card-button:hover {
  background-color: #0f4f43;
}

/* Responsividade */
@media (max-width: 1024px) {
  .card {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .card {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%;
  }
}

</style>
